// JavaScript Document
if(!window.console){
    window.console = {
        log:function(){}
    };
}

var _TrackId='';

(function($) {
       $.stickytoolplugin = {
              defaultsStickyToolParam: {
                    btnContainer: "_stickytoolcontainer",
                    HotelId:'' ,
                    GroupId:'',//Chinmay Gandhi - 12th July 2019 - L2B in MHBE [ RES-2099 ]
                    langkey:"en",
                    tgl_btncolor:'#943a94',
                    btncolor:'#943a94',
                    TriggerAction:false,
                    serverurl:"https://live.ipms247.com/",
                    IsLayout:0,//Chinmay Gandhi - 12th July 2019 - L2B in MHBE [ RES-2099 ]
              }
       };  
	
	$.fn.extend({  
        stickytoolplugin:function(bkStickyConfig) {  
            var options = $.extend({}, $.stickytoolplugin.defaultsStickyToolParam, bkStickyConfig);  
            options.btnContainer = this.attr("id");
            methods._GetDataContain(options);
        }
    });
       
    var methods = {
        test:'123',
            _GetDataContain:function(opt){
                try{
                    if(opt.TriggerAction == false)
                    {
                        //Chinmay Gandhi - 12th July 2019 - L2B in MHBE [ RES-2099 ] - Start
                        if(opt.GroupId != '')
                        {
                            var getUrl=opt.serverurl+'booking/multiproperty_service.php';
                            var param='action=GetStickyContain&lang_code='+opt.langkey+'&GroupCode='+opt.GroupId;
                        }//Chinmay Gandhi - 12th July 2019 - L2B in MHBE [ RES-2099 ] - End
                        else
                        {
                            var getUrl=opt.serverurl+'booking/service.php';
                            var param='action=GetStickyContain&lang_code='+opt.langkey+'&HotelId='+opt.HotelId;
                        }
                        
                        jQuery.ajax({
                               type: "POST",
                               url: getUrl,
                               data: param,
                               success: function(response) {
                                    var response = JSON.parse(jQuery.trim(response));
                                    _TrackId=response.TrackId;
                                    var res = response.content;
                                    opt.IsLayout = response.IsLayout;//Chinmay Gandhi - 12th July 2019 - L2B in MHBE [ RES-2099 ]
                                    var MainContain = "<section id='eRbottom-fix-widget' class='eRbottom-fix-widget full'>"+res+
                                                      "</section>";
    
                                    jQuery("#_stickytoolcontainer").html(MainContain);
                                    jQuery('.btn-tgl').css({'background':opt.tgl_btncolor});
                                    jQuery('.book').css({'background':opt.btncolor});
                               },
                               complete: function(){
                                    methods._RedirectToBooking(opt);
                                    methods._TogglePopup(opt);
                               }
                        });
                    }
                }
                catch(err){
                      console.log('error message _GetDataContain');
                      console.log(err);
                }
            },
            _RedirectToBooking:function(opt){
                try{
                    jQuery('.stickybookbtn').on('click', function(e){
                        jQuery("a.stickybookbtn").removeAttr("href");
                        
                        //Chinmay Gandhi - 12th July 2019 - L2B in MHBE [ RES-2099 ] - Start
                        if(opt.IsLayout == 1)
                            window.open(opt.serverurl+'booking/roomwisedata.php?hid='+opt.HotelId+'&BookingThrough=5&L2BTrackId='+_TrackId+'&selectedLang='+opt.langkey+'&IsLayout='+opt.IsLayout,'_blank');
                        else if(opt.IsLayout == 2)
                            window.open(opt.serverurl+'booking/roomwisedata.php?hid='+opt.GroupId+'&BookingThrough=5&L2BTrackId='+_TrackId+'&selectedLang='+opt.langkey+'&IsLayout='+opt.IsLayout,'_blank');
                        else
                            window.open(opt.serverurl+'booking/roomwisedata.php?hid='+ opt.HotelId +'&BookingThrough=5&L2BTrackId=' + _TrackId,'_blank');
                        //Chinmay Gandhi - 12th July 2019 - L2B in MHBE [ RES-2099 ] - End
                        
                    });
                }
                catch(err){
                    console.log('error message _RedirectToBooking');
                    console.log(err);
                }
            },
            _TogglePopup:function(opt){
                jQuery('#toggle-bottombar').on('click', function(e) {
                    jQuery('#eRbottom-fix-widget').toggleClass('be_hide');
                });
            }
    }
       	
})(jQuery);